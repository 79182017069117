.loaderPage {
	width: 100%;
	height: 100%;
	position: fixed;
	background: RGBA(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;

	.bigPreloader {
		width: 300px;
		height: 300px;
		background: cyan;
		animation-name: preloaderAnimation;
		animation-duration: .5s;
		animation-direction: alternate;
		animation-iteration-count: infinite; } }

@keyframes preloaderAnimation {
	0% {
		opacity: 0.5; }

	100% {
		opacity: 1; } }



.mini-loader {
	width: 48px;
	height: 48px;
	display: block;
	margin: 10px;
	position: relative;
	color: #5038EC;
	box-sizing: border-box;
	animation: rotation_19 2s linear infinite;

	&::after, &::before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		width: 24px;
		height: 24px;
		top: 0;
		background-color: #FF9500;
		border-radius: 50%;
		animation: scale50 1s infinite ease-in-out; }

	&::before {
		top: auto;
		bottom: 0;
		background-color: #5038EC;
		animation-delay: 1s; } }


@keyframes rotation_19 {
	0% {
		transform: rotate(0deg); }


	100% {
		transform: rotate(360deg); } }



@keyframes scale50 {
	0%, 100% {
		transform: scale(0); }


	50% {
		transform: scale(1); } }


