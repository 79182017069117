@use "sass:math";

.cols {
	display: flex;
	flex-wrap: wrap;

	.col {
		box-sizing: border-box;
	}

	&.p7 {
		@include col-padding(7px);
	}

	&.p10 {
		@include col-padding(10px);
	}

	&.p15 {
		@include col-padding(15px);
	}

	&.p30 {
		@include col-padding(30px);
	}

	.ptb0 {
		.col {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.cols-2 {
	.col {
		width: 50%;
	}
}

.cols-3 {
	.col {
		width: 33.333%;
	}
}

.cols-4 {
	.col {
		width: 25%;
	}
}

.cols-5 {
	.col {
		width: 20%;
	}
}


.col-4 {
	width: 33.333%;
}

.col-8 {
	width: 66.666%;
}


@include for-tablet {

	.cols {
		&.pt25 {
			@include col-padding(25px);
		}
	}

	.cols-t-2 {
		.col {
			width: 50%;
		}
	}

	.cols-t-3 {
		.col {
			width: 33.333%;
		}
	}
}

@include for-phone {

	.cols-p-1 {
		.col {
			width: 100%;
		}
	}

	.cols-p-2 {
		.col {
			width: 50%;
		}
	}

	.cols {
		&.pp0 {
			@include col-padding(0px);
		}
	}
}