@mixin transition($s, $t, $fn: linear,  $d: 0s) {
	-webkit-transition: $s $t $fn $d;
	-moz-transition: $s $t $fn $d;
	-o-transition: $s $t $fn $d;
	transition: $s $t $fn $d;
}

@mixin rotate($d) {
	-moz-transform: rotate($d);
	-ms-transform: rotate($d);
	-webkit-transform: rotate($d);
	transform: rotate($d);
}

// $property - свойство
// $expression - значение
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

@mixin bluring($valall, $valie) {
	-webkit-filter: blur($valall);
	filter: blur($valall);
	filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$valie}');
}

@mixin flex($flow: row wrap, $justify: flex-start) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-flex-flow: $flow;
	flex-flow: $flow;

	justify-content: $justify;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

@mixin fill($p:0) {
	content: '';
	position: absolute;
	left: $p;
	top: $p;
	right: $p;
	bottom: $p;
}

@mixin gradient($direction, $list) {
	background: -webkit-linear-gradient($direction, $list);
	background: -moz-linear-gradient($direction, $list);
	background: -o-linear-gradient($direction, $list);
	background: linear-gradient($direction, $list);
}

@mixin col-padding($val) {
	margin-left: -$val;
	@include calc('width', '100% + '+($val*2));

	.col {
		padding: $val;
	}
}

@mixin pos-center() {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

@mixin line-clump($line) {
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}