.connection-head-itms {
	margin: 25px 0 35px;
}

.connection-materials-itms {
	margin: 16px 0 40px;
}

.connection-links-itms {
	margin: 16px 0 12px;

	.smart-search-multiple-result-itm{
		max-width: 66%;
	}

	.title{
		display: inline-block;
	}
}

.connection-links-block{
	.link-itm-table {
		&:not(:last-child) {
			margin-bottom: 90px;
		}
	}
}

.smart-search-result--no-del {
	.smart-search-multiple-result-itm {
		padding-right: 20px;
	}
}

.connections-num-cols {
	margin-bottom: 80px;

	.detail-references__num {
		margin-top: 40px;
	}
}

.graph-container{
	height: 400px;
}

.graph-title{
	margin-bottom: 20px;
}