.source-block {
	padding: 20px 0 30px;

	> .title {
		margin-bottom: 27px;
	}
}

.source-block-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 28px;
	margin-bottom: 32px;

	&-itm {
		position: relative;

		input {
			opacity: 0;
			position: absolute;
			left: 50%;
			top: 100%;

			&:checked ~ .source-block-list-itm__name {
				background: $c_second;
				color: #fff;
				border-color: $c_second;
			}
		}

		&__name {
			display: block;
			background: #FFE8C9;
			border-radius: 8px;
			min-width: 94px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			padding: 0 10px;
			box-sizing: border-box;
			cursor: pointer;
			border: 1px solid $c_second_bg;

			&:hover {
				border-color: $c_second;
			}
		}
	}
}

.source-block-search-top {
	display: flex;
	justify-content: space-between;
	gap: 80px;

	.input-wrap {
		margin-bottom: 0;
		max-width: 780px;
	}

	.btn {
		flex-shrink: 0;
	}

	.smart-search-input{
		margin-bottom: 0;
	}
}

.source-block-search-result {
	margin: 30px 0;
}

.source-search-list {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
	height: 65px;
	overflow: hidden;
	padding-right: 340px;
	transition: all .3s ease-in-out;

	&--open {
		height: auto;
		padding-right: 0;

		.source-search-list__more {
			opacity: 0 !important;
			pointer-events: none !important;
			transition: all .1s ease-in-out !important;
		}
	}

	&__more {
		position: absolute;
		left: calc(100% - 340px);
		top: 20px;
		color: $c_gray_bg;
		cursor: pointer;
		transition: all .4s ease-in-out;
		opacity: 0;
		pointer-events: none;
		transform: translateX(10px);

		&.show {
			opacity: 1;
			pointer-events: auto;
			transform: translateX(0px);
		}

		&:hover {
			color: $c_second;
		}
	}

	&-itm {
		position: relative;
		height: 62px;
		line-height: 64px;
		border: 1px solid $c_gray_bg;
		text-align: center;
		border-radius: 8px;
		padding: 0 60px 0 20px;
		color: $c_main_dark;

		&__remove {
			position: absolute;
			right: 0;
			top: 0;
			height: 62px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				.cross {
					&:before, &:after {
						background-color: $c_second !important;
					}
				}
			}

			.cross {
				&:before, &:after {
					background-color: $c_gray_bg;
					transition: all .2s ease-in-out;
				}
			}
		}
	}
}

.stream-finish-btn {
	margin-bottom: 95px;
}

.stream-detail-source {
	margin: 60px 0 110px;

	&__th {
		color: #666666;
		font-weight: 700;
		margin-top: 2px;
	}

	&__cols {
		display: flex;
		gap: 50px;

		.col {
			flex: 1;
		}
	}

	&__btns {
		display: flex;
		gap: 40px;
		flex-wrap: wrap;
		margin-top: 45px;
	}
}

.stream-detail-source-table {
	width: 100%;
	margin-bottom: 40px;

	&:not(.show-all) {
		tbody {
			tr {
				&:nth-child(n+5) {
					display: none;
				}
			}
		}
	}

	.list-item {
		display: inline-block;
	}

	td {
		padding: 8px 16px;
		vertical-align: middle;
		text-align: center;

		&:first-child {
			text-align: left;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	th {
		vertical-align: bottom;
		padding-bottom: 20px;


		&:first-child {
			text-align: left;
		}
	}

}

.stream-rename-btn {
	cursor: pointer;

	&:hover {
		color: $c_second;
	}


}

.stream-num-block {
	display: flex;
	align-items: center;
	gap: 17px;
	margin-top: 47px;
}

.search-phrases{
	margin: 20px 0;

	&__item{
		user-select: none;
		height: 60px;
		color: $c_main_dark;
		padding: 16px;
		border: 1px solid;
		border-radius: 8px;
		display: inline-block;
		margin: 0 16px 16px 0;
		box-sizing: border-box;
		line-height: 100%;

		&--text{
			border-color: $c_salad_border;
		}

		&--concept{
			border-color: $c_gray_bg;
		}

		&--bool{
			margin-bottom: 4px;
			padding: 6px 10px;
			height: 30px;
			color: $c_d_gray;
			background: $c_salad_bg;
			vertical-align: middle;
			line-height: 100%;
			cursor: pointer;
			border-radius: 8px;
			border-color: transparent;
			transition: .5s ease;

			&:hover{
				border-color: $c_salad_border
			}

			&.OR{
				background: $c_blue_bg;

				&:hover{
					border-color: $c_blue_border
				}
			}

			&.NOT{
				background: $c_red_bg;

				&:hover{
					border-color: $c_red_border
				}
			}
		}

		.cross{
			display: inline-block;
			margin-left: 16px;
			&:before, &:after{
				background: $c_gray;
			}
		}
	}
}