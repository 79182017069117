$F_Intro: 'Intro';

$width_main: 1280;

$fs_big_title: 3em;
$fs_title_mid: 2.25em;
$fs_title: 1.5em;
$fs_title2: 1.25em;
$fs_small: 0.875em;

$color_black: #000;

$c_d_gray: #333333;
$c_mid_gray: #3f3f3f;
$c_gray: #666;
$c_gray_bg: #B3B3B3;
$c_light_gray: #F9F9FF;

$c_select_bg: #EFEFFF;

$c_main: #5038EC;
$c_main_dark: #412779;
$c_purple: #8165CC;
$c_second: #FF9500;
$c_second_bg: #FFE8C9;
$c_red: #F00;

$c_blue: #00CC83;
$c_salad_bg: #ADE8D2;
$c_salad_border: #00CC83;
$c_blue_bg: #ADCFE8;
$c_blue_border: #4375C0;
$c_red_bg: #E8ADAD;
$c_red_border: #C0435A;

:root{
	--c_d_gray: #333333;
	--c_mid_gray: #3f3f3f;
	--c_gray: #666;
	--c_gray_bg: #B3B3B3;
	--c_light_gray: #F9F9FF;
	--c_main: #5038EC;
	--c_main_dark: #412779;
	--c_purple: #8165CC;
	--c_second: #FF9500;
	--c_second_bg: #FFE8C9;
	--c_red: #F00;
	--c_blue: #00CC83;
	--c_salad_bg:  #ADE8D2;
	--c_salad_border: #00CC83;
	--c_blue_bg: #ADCFE8;
	--c_blue_border: #4375C0;
	--c_red_bg: #E8ADAD;
	--c_red_border: #C0435A;
}
