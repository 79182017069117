
.obj-sidebar-name {
	font-weight: bold;
}

.nice-form {

	.cols {
		.input-wrap {
			margin-bottom: 0;
		}
	}

	textarea {
		border-radius: 8px;
		min-width: 100%;
		max-width: 100%;
		width: 100%;
		padding: 20px 24px;
		font-family: $F_Intro;
		font-size: initial;
		box-sizing: border-box;
		border: 1px solid #B3B3B3;
		color: #666;

		@include placeholder {
			font-weight: 300;
		}
	}

	input {
		width: 100%;
		background: $c_light_gray;
		border-radius: 8px;
		padding: 17px;
		box-sizing: border-box;
		outline: none;
		border: 1px solid $c_gray_bg;
		height: 43px;
		line-height: 43px;
		@include transition(all, .2s);

		&.small-input {
			padding: 10px 15px;
			border-radius: 4px;
		}

		&:-webkit-autofill {
			box-shadow: inset 0 0 0 1000px $c_light_gray;
		}

		&:not(:placeholder-shown), &:-webkit-autofill, &:focus {
			background: #fff;

			+ .input-wrap__label {
				transform: translateY(-120%);
				font-size: 11px;
			}
		}

		&:focus {

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #FCFCFC;
			}
		}
	}
}

.date-input {
	position: relative;
	display: inline-block;

	input {
		width: 192px;
		padding-right: 50px;
	}

	&__ico {
		position: absolute;
		top: 50%;
		right: 5px;
		transform: translateY(-50%) translateX(-50%);
	}
}

.input-wrap {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 24px;
	box-sizing: border-box;

	input {
		width: 100%;
		background: $c_light_gray;
		border-radius: 8px;
		padding: 11px 15px 0;
		box-sizing: border-box;
		outline: none;
		height: 43px;
		border: 1px solid $c_gray_bg;
		@include transition(background, .1s, ease, (0s, color, .1s));

		&:-webkit-autofill {
			box-shadow: inset 0 0 0 1000px #fff;
		}

		&:not(:placeholder-shown), &:-webkit-autofill, &:focus {
			background: #fff;
		}

		&:not(:placeholder-shown), &:-webkit-autofill {
			~ .reset-field {
				display: block;
			}
		}

		&:focus {

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #FCFCFC;
			}
		}
	}

	.reset-field {
		display: none;
		position: absolute;
		right: 10px;
		top: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		cursor: pointer;


		&:before, &:after {
			//background: $c_red;
		}
	}

	&.nolabel {

		input {
			padding-top: 21px;
			padding-bottom: 21px;
		}

		&.small {
			input {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}


	&.with-ico {
		input {
			padding-left: 40px;
		}

		.input-wrap__label {
			left: 40px;
		}
	}

	&.small {
		input {
			padding: 14px 18px 6px 65px;
			border-radius: 4px;
		}

	}

	&__text{

		input{
			border-color: $c_blue;
		}
	}

	&__ico {
		position: absolute;
		top: 50%;
		left: 14px;
		transform: translateY(-50%);
	}

	&__label {
		position: absolute;
		left: 15px;
		top: 50%;
		color: $c_gray;
		font-weight: 300;
		font-size: $fs_small;
		transform: translateY(-50%);
		@include transition(all, .2s);
	}

	&.disabled {
		opacity: 0.5;
	}

	&.success {
		input {
			background: #F2FFFB;
			border-color: #27AE60;

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #F2FFFB;
			}
		}

		.input-wrap {
			&__label {
				color: #219653 !important;
			}
		}
	}

	&.error {
		input {
			background: #FFF3F8;
			border-color: #EB5757;

			&:-webkit-autofill {
				box-shadow: inset 0 0 0 1000px #FFF3F8;
			}
		}

		.input-wrap {
			&__label {
				color: #EB5757 !important;
			}
		}
	}

	&.pass-show {
		.pass-show-ico {
			display: block;
		}

		.pass-hide-ico {
			display: none;
		}
	}
}

.pass-hide-ico {
	cursor: pointer;
}

.pass-show-ico {
	display: none;
	cursor: pointer;
}

.input-wrap-itm {
	position: relative;
}

.js-select {
	display: none;
}

.js-select-wrap {
	position: relative;

	select {
		display: none;
	}
}

.custom-select {
	position: relative;

	&__single {
		background: $c_light_gray;
		border-radius: 4px;
		padding: 11px 25px 11px 16px;
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 12px;
			width: 7px;
			height: 7px;
			margin-top: -5px;
			//border-left: 1px solid $c_placeholder;
			//border-bottom: 1px solid $c_placeholder;
			@include rotate(-45deg);
		}
	}

	&__dropdown {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		max-height: 250px;
		overflow: auto;
		background: $c_light_gray;
		margin-top: 6px;
		border-radius: 4px;
		pointer-events: none;
		opacity: 0;
		z-index: 8;
		@include transition(all, .15s);
		transform: translateY(-5px);
	}

	&__dropdown-list {
		list-style: none;
	}

	&__dropdown-item {
		padding: 11px 16px;
		cursor: pointer;

		&:hover {
			background: #f3f4fb;
		}

		&.option-disabled {
			opacity: 0.5;
			pointer-events: none;
			background: #e0e1e8;
		}
	}

	&.is-open {
		.custom-select {
			&__dropdown {
				pointer-events: auto;
				opacity: 1;
				transform: translateY(0px);
			}

			&__single {
				&:after {
					@include rotate(135deg);
					margin-top: -1px;
				}
			}
		}
	}
}

.js-checkbox {
	display: none;

	&.round {
		+ .check_box__item {
			border-radius: 50%;
		}
	}

	&:checked {
		+ .check_box__item {
			//background: $c_main;

			&:before {
				opacity: 1;
			}
		}
	}

}

.check_box {
	display: inline-block;
	vertical-align: middle;
}

.check_box__item {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	//background: $c_line;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: url('../../public/img/ico/check.svg') no-repeat center;
		opacity: 0;
	}
}