
@mixin for-tablet {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin for-phone {
	@media (min-width: 600px) {
		@content;
	}
}