.footer {
	margin: 53px 0 45px;
	color: $c_gray_bg;
	font-weight: 300;

	> .content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-itm {
		font-size: $fs_small;

		&--link {
			color: $c_gray_bg;
			text-decoration: none;
			display: inline-block;
			border-bottom: 1px solid transparent;


			&:hover {
				border-bottom: 1px solid $c_gray_bg;
			}
		}
	}
}